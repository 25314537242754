import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Notification } from '../models/Notification';


@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    constructor(
        private http: HttpClient
    ) {
    }

    getAllNotifications(): Observable<Notification[]> {
        return this.http.get<Notification[]>(`${environment.ridely_api}notification`);
    }
}
