import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProgressBarService {

  private progressBar = new BehaviorSubject<boolean>(true);
  public progressBar$ = this.progressBar.asObservable();

  constructor() { }

  setLoadingProgress(isLoading: boolean) {
    this.progressBar.next(isLoading);
  }
}
