export class UserLogin {

    constructor(
        public _userName: string,
        private _token: string,
        private _email: string,
        private _tokenExpirationDate: number
    ) {
    }

    get token() {
        const unixTime = this._tokenExpirationDate;
        let tokenDate = new Date(unixTime * 1000);

        // verify if token is expired and if it is, return null
        if (!tokenDate || tokenDate < new Date()) {
            return null;
        }
        
        return this._token;
    }

    get email() {
        return this._email;
    }

    get userName() {
        return this._userName;
    }
}