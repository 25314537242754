import { environment } from './../environments/environment';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { Component, Inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { InteractionStatus, PopupRequest } from '@azure/msal-browser';
import {GeolocationHubService} from "./core/hubs/geolocation-hub.service";
import {NotificationHubService} from "./core/hubs/notification-hub.service";

declare var gtag: (arg0: string, arg1: string, arg2: { page_path: any; }) => void;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'ridely';

  constructor(
    private authService: AuthenticationService,
    private geoLocationHub: GeolocationHubService,
    private notificationHub: NotificationHubService,
    private router: Router
  ) {
    const navEndEvents = router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    );

    navEndEvents.subscribe((data: any) => {
      gtag('config', environment.GOOGLE_ANALYTICS_ID, {
        'page_path': data.urlAfterRedirects
      });
    });
  }

  ngOnInit() {
    debugger;
    this.authService.autoLogin();
    this.notificationHub.startConnection();
    // this.geoLocationHub.
  }
}
