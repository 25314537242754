export const environment = {
  production: true,
  ridely_api: 'https://dev-rydly.azurewebsites.net/api/',
  taskSchedule: 'https://dev-rydly.azurewebsites.net/hangfire/jobs/enqueued',
  GOOGLE_API_KEY: 'AIzaSyDjh4nVxb3VcuYvyYWMf8DpnhdzuFplvgo',
  GOOGLE_ANALYTICS_ID: 'G-EN6GTM5H7M',
  google_calendar_api: "AIzaSyDHFUg-VLvetQsszagaQWqUsHSzyPoOeTs",
  RYDLY_HUB: 'https://dev-rydly.azurewebsites.net/',
  MICROSOFT: {
    CLIENT_ID: "bfd9a2d4-e3f4-47c6-a5db-5e62e81d5f87",
    TENANT_ID: "https://login.microsoftonline.com/5d96588e-ce5a-474c-9b78-32880bc32133",
    REDIRECT_URI: "https://dev.admin.rydly.co.za",
    AUTHORITY_URL: "https://login.microsoftonline.com/common/",
    POWERBI_URL: "https://api.powerbi.com/",    // Endpoint for URL for power bi api
    POWERBI_SCOPES: ["https://analysis.windows.net/powerbi/api/Report.Read.All"],
    WORKSPACE_ID: "", // ID of the workspace where the report is hosted
    REPORT_ID: "",
    GROUP_ID: ""
  }
};
