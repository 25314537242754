import { MapsService } from './../../core/services/maps.service';
import { UserLogin } from './../../core/models/UserLogin';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { Component, AfterViewInit, EventEmitter, Output, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { NotificationService } from 'src/app/modules/notifications/services/notifications.service';
import { Notification } from '../../modules/notifications/models/Notification';

declare var $: any;

@Component({
  selector: 'app-vertical-navigation',
  templateUrl: './vertical-navigation.component.html'
})
export class VerticalNavigationComponent implements OnInit, OnDestroy{
  @Output() toggleSidebar = new EventEmitter<void>();
  @Output() toggleLayoutSettings = new EventEmitter<boolean>();

  public config: PerfectScrollbarConfigInterface = {};
  public showSearch = false;
  private isAuthenticated: boolean = false;
  public user!: UserLogin;
  public formatedAddress = '';
  public price = 0.0;
  public distance = 0;
  public duration = 0;
  public showSettings = false;

  notifications: Notification[] = [];
  mymessages: Object[] = [];

  options = {
    componentRestictions: {
      country: 'US'
    }
  }
  
  constructor(
    private authService: AuthenticationService, 
    private translate: TranslateService, 
    private notificationService: NotificationService,
    private mapService: MapsService,
    private router: Router
    ) {
    translate.setDefaultLang('en');
  }

  @ViewChild("placesRef")
  placesRef!: GooglePlaceDirective;

  @ViewChild("placesRefDestination")
  placesRefDestination!: GooglePlaceDirective;
    
  public handleAddressChange(address: Address) {
      this.formatedAddress = address.formatted_address;
  }

  ngOnInit() {
    this.authService.loggedInUser.subscribe(user => {
      this.isAuthenticated = !!user;
      this.user = user;

      this.getAllNotifications();
    }, error => {
      console.log(error);
    });

  }

  getAllNotifications() {
    this.notificationService.getAllNotifications().subscribe(data => {
      this.notifications = data;
    });
  }

  public selectedLanguage: any = {
    language: 'English',
    code: 'en',
    type: 'US',
    icon: 'us'
  }

  public languages: any[] = [{
    language: 'English',
    code: 'en',
    type: 'US',
    icon: 'us'
  },
  {
    language: 'Español',
    code: 'es',
    icon: 'es'
  },
  {
    language: 'Français',
    code: 'fr',
    icon: 'fr'
  },
  {
    language: 'German',
    code: 'de',
    icon: 'de'
  }]

  changeLanguage(lang: any) {
    this.translate.use(lang.code)
    this.selectedLanguage = lang;
  }

  showSettingsMenu() {
    this.toggleLayoutSettings.emit(!this.showSettings);
  }

  logout() {
    this.authService.logout();
  }

  /**
   * clear all the subscriptions
   */
  ngOnDestroy(): void {
    // this.userSub.unsubscribe();
  }
}
