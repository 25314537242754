import { Routes } from "@angular/router";
import { FullComponent } from "./layouts/full/full.component";
import { BlankComponent } from "./layouts/blank/blank.component";
import { AuthGuard } from "./authentication/auth.guard";
import { MsalGuard } from "@azure/msal-angular";
export const Approutes: Routes = [
  {
    path: "",
    component: FullComponent,
    canActivate: [AuthGuard, MsalGuard],
    children: [
      { path: "", redirectTo: "/authentication/login", pathMatch: "full" },
      {
        path: "dashboard",
        loadChildren: () =>
          import("./modules/dashboards/dashboard.module").then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: "trip",
        loadChildren: () =>
          import("./modules/trips/trip.module").then((m) => m.TripModule),
      },
      {
        path: "",
        children: [
          {
            path: "jobs",
            loadChildren: () => import('./modules/jobs/jobs.module').then((m) => m.JobsModule)
          }
        ]
      },
      {
        path: "payments",
        loadChildren: () =>
          import("./modules/payments/payments.module").then((m) => m.PaymentsModule),
      },
      {
        path: "promotions",
        loadChildren: () =>
          import("./modules/promotions/promotions.module").then((m) => m.PromotionsModule),
      },
      {
        path: "documents",
        loadChildren: () => import("./modules/document-library/document-library.module").then((m) => m.DocumentLibraryModule)
      },
      {
        path: "apps",
        loadChildren: () =>
          import("./modules/apps.module").then((m) => m.AppsModule),
      },
      {
        path: "reports",
        loadChildren: () =>
          import("./modules/reports/reports.module").then((m) => m.ReportsModule),
      },
      {
        path: "users",
        loadChildren: () =>
          import("./modules/users/users.module").then((m) => m.UsersModule),
      },
      {
        path: "vehicles",
        loadChildren: () => import("./modules/vehicles/vehicles.module").then((m) => m.VehiclesModule)
      }
    ],
  },
  {
    path: "",
    component: BlankComponent,
    children: [
      {
        path: "authentication",
        loadChildren: () =>
          import("./authentication/authentication.module").then(
            (m) => m.AuthenticationModule
          ),
      },
    ],
  },
  {
    path: "**",
    redirectTo: "/authentication/404",
  },
];
