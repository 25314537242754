import { RouteInfo } from "./vertical-sidebar.metadata";

export const ROUTES: RouteInfo[] = [
  {
    path: "dashboard",
    title: "Dashboards",
    icon: "Home",
    class: "has-arrow",
    extralink: false,
    label: "3",
    labelClass: "badge bg-info sidebar-badge",
    submenu: [
      {
        path: "/dashboard/modern",
        title: "Users Overview",
        icon: "mdi mdi-adjust",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        submenu: [],
      },
      {
        path: "/dashboard/overview",
        title: "Trip Overview",
        icon: "mdi mdi-adjust",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        submenu: [],
      },
      {
        path: "/dashboard/sale",
        title: "Sales Overview",
        icon: "mdi mdi-adjust",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        submenu: [],
      }
    ],
  },
  {
    path: "/users/list",
    title: "User Management",
    icon: "Users",
    class: "has-arrow",
    extralink: false,
    label: "",
    labelClass: "",
    submenu: [
      {
        path: "/users/list",
        title: "Customers",
        icon: "User",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        submenu: [],
      },
      {
        path: "/users/verification",
        title: "Profile Verifications",
        icon: "User",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        submenu: [],
      },
      {
        path: "/users/ratings",
        title: "Ratings",
        icon: "User",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        submenu: [],
      },
      {
        path: "/users/roles/list",
        title: "Roles",
        icon: "",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        submenu: [],
      },
    ],
  },
  {
    path: "/trip/management",
    title: "Trips",
    icon: "map",
    class: "has-arrow",
    extralink: false,
    label: "",
    labelClass: "",
    submenu: [
      {
        path: "/trip/management",
        title: "Ride Management",
        icon: "mdi mdi-car",
        class: "has-arrow",
        extralink: false,
        label: "",
        labelClass: "",
        submenu: [
          {
            path: "/trip/management",
            title: "View Trips",
            icon: "",
            class: "",
            extralink: false,
            label: "",
            labelClass: "",
            submenu: [],
          },
          {
            path: "/trip/create",
            title: "Create Trip",
            icon: "",
            class: "",
            extralink: false,
            label: "",
            labelClass: "",
            submenu: [],
          },
        ],
      },
      {
        path: "/trip/requests/management",
        title: "Request Management",
        icon: "mdi mdi-car-connected",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        submenu: [],
      },
      {
        path: "/trip/fares/management",
        title: "Trip Fares",
        icon: "mdi mdi-car-connected",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        submenu: [],
      },
      {
        path: "/apps/fullcalendar",
        title: "Scheduling & Calendar",
        icon: "mdi mdi-calendar",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        submenu: [],
      },
      {
        path: "/trip/analyzer",
        title: "Trip Map Overview",
        icon: "mdi mdi-car",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        submenu: [],
      },
      {
        path: "/trip/realtime",
        title: "Real Time Monitor",
        icon: "mdi mdi-car",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        submenu: [],
      },
    ],
  },
  {
    path: "/vehicles/management",
    title: "Vehicles",
    icon: "mdi mdi-car",
    class: "has-arrow",
    extralink: false,
    label: "",
    labelClass: "",
    submenu: [
      {
        path: "/vehicles/list",
        title: "Vehicle Management",
        icon: "",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        submenu: []
      },
      {
        path: "/vehicles/inspection",
        title: "Vehicle Inspection",
        icon: "",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        submenu: []
      },
      {
        path: "/vehicles/gallery",
        title: "Gallery",
        icon: "",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        submenu: []
      },
    ]
  },
  {
    path: "/payments",
    title: "Payments",
    icon: "mdi mdi-cash-usd",
    class: "has-arrow",
    extralink: false,
    label: "",
    labelClass: "",
    submenu: [
      {
        path: "/payments/management",
        title: "Payment Management",
        icon: "",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        submenu: [],
      },
      {
        path: "/payments/wallet/management",
        title: "Wallet Management",
        icon: "",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        submenu: [],
      },
      {
        path: "/payments/create",
        title: "Payment Dispatch",
        icon: "",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        submenu: [],
      },
      {
        path: "/payments/refunds",
        title: "Refunds",
        icon: "",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        submenu: [],
      }
    ]
  },
  {
    path: "/promotions",
    title: "Promotions",
    icon: "mdi mdi-cash-usd",
    class: "has-arrow",
    extralink: false,
    label: "",
    labelClass: "",
    submenu: [
      {
        path: "/promotions/management",
        title: "Promo Management",
        icon: "",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        submenu: [],
      }
    ]
  },
  {
    path: "/apps/invoice",
    title: "Invoices",
    icon: "Server",
    class: "has-arrow",
    extralink: false,
    label: "",
    labelClass: "",
    submenu: [
      {
        path: "/apps/invoice",
        title: "Invoices",
        icon: "mdi mdi-cash-usd",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        submenu: [],
      },
      {
        path: "/apps/addinvoice",
        title: "Add Invoice",
        icon: "mdi mdi-cash-usd",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        submenu: [],
      },
    ],
  },
  {
    path: "/reports/management",
    title: "Reports",
    icon: "clipboard",
    class: "",
    extralink: false,
    label: "",
    labelClass: "",
    submenu: []
  },{
    path: "/apps/documents",
    title: "Document Library",
    icon: "book",
    class: "has-arrow",
    extralink: false,
    label: "",
    labelClass: "",
    submenu: [
      {
        path: "/documents/list",
        title: "List All Documents",
        icon: "",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        submenu: [],
      }
    ],
  },
  {
    path: "/apps/documents",
    title: "Notifications",
    icon: "mail",
    class: "has-arrow",
    extralink: false,
    label: "",
    labelClass: "",
    submenu: [
      {
        path: "/apps/notifications/list",
        title: "View Notifications",
        icon: "",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        submenu: [],
      },
      // {
      //   path: "/apps/mail/inbox",
      //   title: "Inbox",
      //   icon: "",
      //   class: "",
      //   extralink: false,
      //   label: "",
      //   labelClass: "",
      //   submenu: [],
      // },
      {
        path: "/apps/task/scheduling",
        title: "Task Scheduling",
        icon: "",
        class: "",
        extralink: false,
        label: "",
        labelClass: "",
        submenu: [],
      },
    ],
  },
];
