import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { exhaustMap, finalize, take } from 'rxjs/operators';
import { ProgressBarService } from './progress.bar.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  constructor(
    private authService: AuthenticationService,
    private progressService: ProgressBarService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.progressService.setLoadingProgress(true);

    return this.authService.loggedInUser.pipe(
      take(1),        // ensures that only the first emitted value is taken from the observable stream
      exhaustMap(user => {
        if (!user) {
          return next.handle(req).pipe(
            finalize(() => {
              this.progressService.setLoadingProgress(false);
            })
          );
        } 

        const modifiedReq = req.clone({
          headers: req.headers.set('Authorization', 'Bearer ' + user.token)
        });

        return next.handle(modifiedReq).pipe(
          finalize(() => {
            this.progressService.setLoadingProgress(false);
          })
        );

      }));
  }
}
