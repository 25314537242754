import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { map, take } from 'rxjs/operators';

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {

    constructor(
        private authService: AuthenticationService,
        private router: Router
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, router: RouterStateSnapshot): boolean | Promise<boolean | UrlTree> | Observable<boolean | UrlTree> {
        return this.authService.loggedInUser.pipe(
            take(1),
            map(user => {
            
                const isAuth = !!user;

                if (isAuth) { 
                    return true;
                } else {
                    return this.router.createUrlTree(['/authentication/login']);
                }
        }));
    }

}