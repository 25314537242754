<!-- ============================================================== -->
<!-- toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-start me-auto">
  <li class="nav-item d-none d-lg-block">
    <a
      (click)="toggleSidebar.emit()"
      class="nav-link sidebartoggler waves-effect waves-light"
      href="javascript:void(0)"
    >
      <i class="mdi mdi-menu fs-7"></i>
    </a>
  </li>
  <!-- ============================================================== -->
  <!-- mega menu -->
  <!-- ============================================================== -->
  <li class="nav-item mega-dropdown" ngbDropdown [autoClose]="false">
    <a
      ngbDropdownToggle
      class="nav-link waves-effect waves-dark"
      href="javascript:void(0)"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <span class="d-none d-md-block"
        >Quick Menu
        <i-feather name="chevron-down"></i-feather>
      </span>
      <span class="d-block d-md-none">
        <i class="mdi mdi-dialpad fs-7"></i>
      </span>
    </a>
    <div class="dropdown-menu dropdown-menu-animate-up" ngbDropdownMenu>
      <div class="mega-dropdown-menu row">
        <div class="col-lg-6 col-xlg-2 m-b-30">
          <h4 class="mb-4">Active Trips</h4>
          <!-- CAROUSEL -->
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d114584.73585386139!2d28.04002455!3d-26.1715215!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e950c68f0406a51%3A0x238ac9d9b1d34041!2sJohannesburg!5e0!3m2!1sen!2sza!4v1659994739828!5m2!1sen!2sza" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          <!-- End CAROUSEL -->
        </div>
        <div class="col-lg-3 m-b-30">
          <h4 class="mb-4">Rate Calculator: </h4>
          <!-- Rate Calculator -->
          <form>
            <div class="mb-3">
              <input ngx-google-places-autocomplete class="form-control" [options]='options' #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)" placeholder="Enter starting point"/>
            </div>
            <div class="mb-3">
              <input ngx-google-places-autocomplete class="form-control" [options]='options' #placesRefDestination="ngx-places" (onAddressChange)="handleAddressChange($event)" placeholder="Enter Destination"/>
            </div>
            <div class="mb-3">
              <input type="number" class="form-control" id="exampleInputname1" placeholder="Enter Trip Fare" />
            </div>
            <button type="submit" class="btn btn-info">Submit</button>
          </form>
        </div>
        <div class="col-lg-3 col-xlg-4 m-b-30">
          <h4 class="mb-4">Trip Fare: </h4>
          <!-- List style -->
          <ul class="list-style-none">
            <li>
              <a href="javascript:void(0)">Price: {{ price }}</a>
            </li>
            <li>
              <a href="javascript:void(0)">Distance: {{ distance }}</a>
            </li>
            <li>
              <a href="javascript:void(0)">Duration: {{ duration }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </li>
  <!-- ============================================================== -->
  <!-- End mega menu -->
  <!-- ============================================================== -->
  <!-- ============================================================== -->
  <!-- create new -->
  <!-- ============================================================== -->
  <li class="nav-item dropdown" ngbDropdown>
    <a
      ngbDropdownToggle
      class="nav-link dropdown-toggle"
      href="javascript:void(0)"
      id="navbarDropdown"
      role="button"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <span class="d-none d-md-block"
        >Create New
        <i-feather name="chevron-down"></i-feather>
      </span>
      <span class="d-block d-md-none">
        <i class="fa fa-plus"></i>
      </span>
    </a>
    <div class="dropdown-menu dropdown-menu-animate-up" aria-labelledby="navbarDropdown" ngbDropdownMenu>
      <a class="dropdown-item" [routerLink]="['/apps/addinvoice']">Invoice</a>
      <a class="dropdown-item" href="https://ridely.atlassian.net/servicedesk/customer/portal/1/group/1/create/1" target="_blank">Support Ticket</a>
    </div>
  </li>
  <!-- ============================================================== -->
  <!-- Search -->
  <!-- ============================================================== -->
  <li class="nav-item search-box">
    <a
      (click)="showSearch = !showSearch"
      class="nav-link waves-effect waves-dark"
      href="javascript:void(0)"
    >
      <i-feather name="search"></i-feather>
    </a>
    <form
      class="app-search position-absolute"
      [ngClass]="{ 'show-search': showSearch }"
    >
      <input
        type="text"
        class="form-control"
        placeholder="Search &amp; enter"
      />
      <a class="srh-btn" (click)="showSearch = !showSearch">
        <i class="ti-close"></i>
      </a>
    </form>
  </li>
</ul>
<!-- ============================================================== -->
<!-- Right side toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-end">
  <!-- ============================================================== -->
  <!-- create new -->
  <!-- ============================================================== -->
  <!-- <li class="nav-item dropdown" ngbDropdown placement="bottom-end">
    <a
      ngbDropdownToggle
      class="nav-link dropdown-toggle"
      href="javascript:void(0)"
      id="navbarDropdown"
      role="button"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <i class="flag-icon flag-icon-{{ selectedLanguage.icon }}"></i>
    </a>
    <div class="dropdown-menu dropdown-menu-animate-up" aria-labelledby="navbarDropdown" ngbDropdownMenu>
      <span
        class="dropdown-item"
        href="#"
        *ngFor="let lang of languages"
        (click)="changeLanguage(lang)"
      >
        <i class="flag-icon flag-icon-{{ lang.icon }}"></i>
        <span
          >{{ lang.language }}
          <span *ngIf="lang.type">({{ lang.type }})</span>
        </span>
      </span>
    </div>
  </li> -->
  <!-- ============================================================== -->
  <!-- Comment -->
  <!-- ============================================================== -->
  <li class="nav-item dropdown" ngbDropdown placement="bottom-end">
    <a
      ngbDropdownToggle
      class="nav-link dropdown-toggle waves-effect waves-dark"
      href="javascript:void(0)"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <i-feather name="bell"></i-feather>
    </a>
    <div class="dropdown-menu mailbox dropdown-menu-end dropdown-menu-animate-up" ngbDropdownMenu>
      <span class="with-arrow">
        <span class="bg-primary"></span>
      </span>
      <ul class="list-style-none">
        <li>
          <div class="drop-title bg-primary text-white">
            <h4 class="mb-0 mt-1">{{ notifications.length}} New</h4>
            <span class="font-light">Notifications</span>
          </div>
        </li>
        <li>
          <div class="message-center notifications" [perfectScrollbar]="config">
            <!-- Message -->
            <a
              href="javascript:void(0)"
              class="message-item border-bottom d-block text-decoration-none"
              *ngFor="let notification of notifications"
            >
              <span class="btn btn-circle rounded-circle {{ notification.button }}">
                <i class="{{ notification.icon }}"></i>
              </span>
              <span class="mail-contnet d-inline-block w-75 ps-3 align-middle">
                <h5 class="message-title">{{ notification.title }}</h5>
                <span class="mail-desc fs-2 d-block text-muted text-truncate">{{ notification.subject }}</span>
                <span class="time fs-2 d-block text-muted text-truncate">{{ notification.time }}</span>
              </span>
            </a>
          </div>
        </li>
        <li class="border-top p-3 d-grid">
          <a class="btn btn-info text-white" [routerLink]="['/apps/notifications/list']">Check all notifications</a>
        </li>
      </ul>
    </div>
  </li>
  <!-- ============================================================== -->
  <!-- End Comment -->
  <!-- ============================================================== -->
  <!-- ============================================================== -->
  <!-- Messages -->
  <!-- ============================================================== -->
  <li class="nav-item dropdown" ngbDropdown placement="bottom-end">
    <a
      ngbDropdownToggle
      class="nav-link dropdown-toggle waves-effect waves-dark"
      href="javascript:void(0)"
      id="2"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <i-feather name="message-square"></i-feather>
    </a>
    <div
      class="dropdown-menu dropdown-menu-end mailbox dropdown-menu-animate-up"
      aria-labelledby="2"
      ngbDropdownMenu
    >
      <span class="with-arrow">
        <span class="bg-danger"></span>
      </span>
      <ul class="list-style-none">
        <li>
          <div class="drop-title text-white bg-danger">
            <h4 class="mb-0 mt-1">5 New</h4>
            <span class="font-light">Messages</span>
          </div>
        </li>
        <li>
          <div class="message-center message-body" [perfectScrollbar]="config">
            <!-- Message -->
            <a
              href="javascript:void(0)"
              class="message-item border-bottom d-block text-decoration-none"
              *ngFor="let mymessage of mymessages"
            >
              <span class="user-img position-relative d-inline-block">
                <img
                  src="{{ mymessage.useravatar }}"
                  alt="user"
                  class="rounded-circle"
                />
                <span
                  class="profile-status rounded-circle d-inline-block {{ mymessage.status }} float-end"
                ></span>
              </span>
              <span class="mail-contnet d-inline-block w-75 ps-3 align-middle">
                <h5 class="message-title">{{ mymessage.from }}</h5>
                <span class="mail-desc fs-2 d-block text-muted text-truncate">{{ mymessage.subject }}</span>
                <span class="time fs-2 d-block text-muted text-truncate">{{ mymessage.time }}</span>
              </span>
            </a>
          </div>
        </li>
        <li>
          <a
            class="
              text-center
              link
              d-flex
              align-items-center
              justify-content-center
            "
            href="javascript:void(0);"
          >
            <b>See all e-Mails</b>
            <i class="fa fa-angle-right ms-1"></i>
          </a>
        </li>
      </ul>
    </div>
  </li>
  <!-- ============================================================== -->
  <!-- End Messages -->
  <!-- ============================================================== -->
  <!-- ============================================================== -->
  <!-- User profile and search -->
  <!-- ============================================================== -->
  <li class="nav-item" ngbDropdown placement="bottom-end">
    <a
      ngbDropdownToggle
      class="nav-link text-muted waves-effect waves-dark pro-pic"
      href="javascript:void(0)"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <img
        src="assets/images/users/user4.jpg"
        alt="user"
        class="rounded-circle"
        width="31"
      />
    </a>
    <div class="dropdown-menu-end user-dd dropdown-menu-animate-up" ngbDropdownMenu>
      <span class="with-arrow">
        <span class="bg-primary"></span>
      </span>
      <div
        class="
          d-flex
          no-block
          align-items-center
          p-3
          bg-primary
          text-white
          mb-2
        "
      >
        <div class="">
          <img
            src="assets/images/users/user4.jpg"
            alt="user"
            class="img-circle"
            width="60"
          />
        </div>
        <div class="ms-2">
          <h4 class="mb-0">{{ user?.userName }}</h4>
          <p class="mb-0">{{ user?.email }}</p>
        </div>
      </div>
      <a class="dropdown-item" [routerLink]="['/apps/users/my-profile/', user.email]">
        <i class="ti-user me-1 ms-1"></i> My Profile</a
      >
      <a class="dropdown-item" [routerLink]="['/apps/mail/inbox']">
        <i class="ti-email me-1 ms-1"></i> Inbox</a
      >
      <div class="dropdown-divider"></div>
      <a class="dropdown-item" (click)="showSettingsMenu()">
        <i class="ti-settings me-1 ms-1"></i> Account Setting</a
      >
      <a class="dropdown-item" [routerLink]="['/apps/task/scheduling']">
        <i class="ti-graph me-1 ms-1"></i>Task Scheduling</a
      >
      <div class="dropdown-divider"></div>
      <a class="dropdown-item" href="https://ridely.atlassian.net/servicedesk/customer/portal" target="_blank">
        <i class="fas fa-info-circle me-1 ms-1"></i> Help Center</a
      >
      <div class="dropdown-divider"></div>
      <a class="dropdown-item" (click)="logout()">
        <i class="fa fa-power-off me-1 ms-1"></i> Logout</a
      >
      <div class="dropdown-divider"></div>
    </div>
  </li>
  <!-- ============================================================== -->
  <!-- User profile and search -->
  <!-- ============================================================== -->
</ul>
