import {Injectable} from '@angular/core';
import {HttpTransportType, HubConnection, HubConnectionBuilder, RetryContext} from "@microsoft/signalr";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class NotificationHubService {
  public notificationConnection: HubConnection
  constructor() {
    this.notificationConnection = new HubConnectionBuilder()
      .withUrl(`${environment.RYDLY_HUB}notificationhub`, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
      }).withAutomaticReconnect({
        nextRetryDelayInMilliseconds(retryContext: RetryContext): number | null {
          if (retryContext.elapsedMilliseconds < 150000) {
            return 0;
          } else {
            // If the elasped time exceeds 60 seconds,
            return null;
          }
        }
      }).build();

    this.onConnected();
  }

  onConnected() {
    this.notificationConnection.on("SuccessfulConnection", (data) => {
      sessionStorage.setItem("socketId", data);
    });
  }

  startConnection() {
    this.notificationConnection
      .start()
      .then(() => {
        debugger;
        console.info("Notification hub connection started");

        this.reconnecting();
        this.reconnected();
        this.closed();
      })
  }

  reconnecting() {
    this.notificationConnection.onreconnecting(async() => {
    });
  }

  reconnected() {
    this.notificationConnection.onreconnected(async (data: any) => {
      // retrieve user details
    })
  }

  closed() {
    this.notificationConnection.onclose(async () => {
      setTimeout(() => {
        this.startConnection();
      }, 3000);
    });
  }
}
