<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div
  id="main-wrapper"
  [ngClass]="{ 'show-sidebar': showMobileMenu }"
  [dir]="options.dir"
  [attr.data-theme]="options.theme"
  [attr.data-layout]="options.layout"
  [attr.data-sidebartype]="options.sidebartype"
  [attr.data-sidebar-position]="options.sidebarpos"
  [attr.data-header-position]="options.headerpos"
  [attr.data-boxed-layout]="options.boxed"
  data-sidebartype="full"
>
  <!-- ============================================================== -->
  <!-- Topbar header - style you can find in topbar.scss -->
  <!-- ============================================================== -->
  <header class="topbar" [attr.data-navbarbg]="options.navbarbg">
    <nav
      class="d-flex top-navbar navbar-expand-lg"
      [ngClass]="options.navbarbg == 'skin6' ? 'navbar-light' : 'navbar-dark'"
    >
      <div
        class="navbar-header"
        [ngClass]="expandLogo ? 'expand-logo' : ''"
        [attr.data-logobg]="options.logobg"
      >
        <!-- This is for the sidebar toggle which is visible on mobile only -->
        <a
          (click)="showMobileMenu = !showMobileMenu"
          class="nav-toggler waves-effect waves-light d-block d-lg-none"
          href="javascript:void(0)"
        >
          <i [ngClass]="showMobileMenu ? 'ti-close' : 'ti-menu'"></i>
        </a>
        <!-- ============================================================== -->
        <!-- Logo -->
        <!-- ============================================================== -->
        <a class="navbar-brand" href="/">
          <!-- Logo text -->
          <span class="logo-text">
            <!-- dark Logo text -->
            <img
              src="assets/images/logos/ridely-dark-text.png" height="40"
              alt="homepage"
              class="dark-logo"
            />
            <!-- Light Logo text -->
            <img
              src="assets/images/logos/ridely-white-text.png" height="40"
              alt="homepage"
              class="light-logo"
            />
          </span>
        </a>
        <!-- ============================================================== -->
        <!-- End Logo -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Toggle which is visible on mobile only -->
        <!-- ============================================================== -->
        <a
          class="topbartoggler d-block d-lg-none waves-effect waves-light"
          href="javascript:void(0)"
          (click)="isCollapsed = !isCollapsed"
          [attr.aria-expanded]="!isCollapsed"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
        >
          <i class="ti-more"></i>
        </a>
      </div>
      <!-- ============================================================== -->
      <!-- End Logo -->
      <!-- ============================================================== -->
      <div
        class="navbar-collapse collapse"
        id="navbarSupportedContent"
        [attr.data-navbarbg]="options.navbarbg"
        [ngbCollapse]="!isCollapsed"
      >
        <!-- Horizontal Header -->
        <div
          *ngIf="options.layout === 'horizontal'; else vheader"
          class="w-100"
        >
          <app-horizontal-navigation
            class="w-100 d-flex align-items-center justify-content-between"
          >
          </app-horizontal-navigation>
        </div>

        <!-- Vertical Header -->
        <ng-template #vheader>
          <div class="w-100">
            <app-vertical-navigation (toggleSidebar)="toggleSidebarType()" (toggleLayoutSettings)="toggleLayoutSettings($event)">
            </app-vertical-navigation>
          </div>
        </ng-template>
      </div>
    </nav>
  </header>
  <!-- ============================================================== -->
  <!-- Left Sidebar - style you can find in sidebar.scss  -->
  <!-- ============================================================== -->
  <aside
    class="left-sidebar"
    [attr.data-sidebarbg]="options.sidebarbg"
    (mouseover)="Logo()"
    (mouseout)="Logo()"
  >
    <!-- Sidebar scroll-->
    <div *ngIf="options.layout === 'horizontal'; else vlayout">
      <app-horizontal-sidebar></app-horizontal-sidebar>
    </div>

    <!-- Horizontal Header -->
    <ng-template #vlayout>
      <div class="scroll-sidebar" [perfectScrollbar]="config">
        <app-vertical-sidebar
          [showClass]="showMobileMenu"
          (notify)="handleClick($event)"
        >
        </app-vertical-sidebar>
      </div>
    </ng-template>
    <!-- End Sidebar scroll-->
  </aside>
  <!-- ============================================================== -->
  <!-- End Left Sidebar - style you can find in sidebar.scss  -->
  <!-- ============================================================== -->
  <!-- ============================================================== -->
  <!-- Page wrapper  -->
  <!-- ============================================================== -->
  <div class="page-wrapper">
    <mat-progress-bar *ngIf="progressBar" mode="query" color="accent"></mat-progress-bar>
    <app-breadcrumb></app-breadcrumb>
    <!-- ============================================================== -->
    <!-- Container fluid  -->
    <!-- ============================================================== -->
    <div class="container-fluid">
      <!-- ============================================================== -->
      <!-- Start Page Content -->
      <!-- ============================================================== -->
      <router-outlet></router-outlet>
      <!-- ============================================================== -->
      <!-- End Start Page Content -->
      <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Container fluid  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- footer -->
    <!-- ============================================================== -->
    <footer class="footer text-center">
      All Rights Reserved by Qualtrix Software Technologies. Designed and Developed by
      <a href="https://thatolesetla.com">Qualtrix Software Technologies</a>.
    </footer>
    <!-- ============================================================== -->
    <!-- End footer -->
    <!-- ============================================================== -->
  </div>
  <!-- ============================================================== -->
  <!-- End Page wrapper  -->
  <!-- ============================================================== -->
  <aside class="customizer" [ngClass]="{ 'show-service-panel': showSettings }">
    <a *ngIf="showSettings == true"
      href="javascript:void(0)"
      class="service-panel-toggle"
      (click)="showSettings = !showSettings"
    >
      <i class="fa fa-spin fa-cog"></i>
    </a>
    <div class="customizer-body custom-pills" [perfectScrollbar]="config">
      <ul
        ngbNav
        #nav="ngbNav"
        [(activeId)]="active"
        class="nav-pills nav-justified"
      >
        <li [ngbNavItem]="1">
          <a ngbNavLink>
            <i class="mdi mdi-wrench fs-6"></i>
          </a>
          <ng-template ngbNavContent>
            <div class="p-3 border-bottom">
              <h5 class="font-medium mb-4 mt-2">Layout Settings</h5>
              <div class="form-check mt-2">
                <input
                  type="checkbox"
                  class="form-check-input"
                  name="layout"
                  id="layout"
                  [attr.checked]="
                    options.layout == 'horizontal' ? 'checked' : null
                  "
                  (change)="
                    options.layout =
                      options.layout == 'vertical' ? 'horizontal' : 'vertical'
                  "
                  (click)="
                    options.boxed = options.boxed == 'full' ? 'boxed' : 'full'
                  "
                />
                <label class="form-check-label" for="layout">Horizontal</label>
              </div>

              <div class="form-check mt-2">
                <input
                  type="checkbox"
                  class="form-check-input"
                  name="theme-view"
                  id="theme-view"
                  [attr.checked]="options.theme == 'dark' ? 'checked' : null"
                  (change)="
                    options.theme = options.theme == 'light' ? 'dark' : 'light'
                  "
                />
                <label class="form-check-label" for="theme-view"
                  >Dark Theme</label
                >
              </div>
              <div class="form-check mt-2">
                <input
                  type="checkbox"
                  class="form-check-input"
                  name="sidebar-position"
                  id="sidebar-position"
                  [attr.checked]="
                    options.sidebarpos == 'fixed' ? 'checked' : null
                  "
                  (change)="
                    options.sidebarpos =
                      options.sidebarpos == 'fixed' ? 'absolute' : 'fixed'
                  "
                />
                <label class="form-check-label" for="sidebar-position"
                  >Fixed Sidebar</label
                >
              </div>
              <div class="form-check mt-2">
                <input
                  type="checkbox"
                  class="form-check-input"
                  name="header-position"
                  id="header-position"
                  [attr.checked]="
                    options.headerpos == 'fixed' ? 'checked' : null
                  "
                  (change)="
                    options.headerpos =
                      options.headerpos == 'fixed' ? 'absolute' : 'fixed'
                  "
                />
                <label class="form-check-label" for="header-position"
                  >Fixed Header</label
                >
              </div>
              <div class="form-check mt-2">
                <input
                  type="checkbox"
                  class="form-check-input"
                  name="boxed-layout"
                  id="boxed-layout"
                  [attr.checked]="options.boxed == 'boxed' ? 'checked' : null"
                  (change)="
                    options.boxed = options.boxed == 'full' ? 'boxed' : 'full'
                  "
                />
                <label class="form-check-label" for="boxed-layout"
                  >Boxed Layout</label
                >
              </div>
              <div class="form-check mt-2">
                <input
                  type="checkbox"
                  class="form-check-input"
                  name="rtl-layout"
                  id="rtl-layout"
                  [attr.checked]="options.dir == 'rtl' ? 'checked' : null"
                  (change)="options.dir = options.dir == 'rtl' ? 'ltr' : 'rtl'"
                />
                <label class="form-check-label" for="rtl-layout">RTL</label>
              </div>
            </div>
            <div
              class="p-3 border-bottom"
              *ngIf="options.layout === 'vertical'"
            >
              <h5 class="font-medium mb-4 mt-2">Sidebar Types</h5>
              <div class="form-check mt-2">
                <input
                  type="radio"
                  class="form-check-input"
                  name="sidebar"
                  [(ngModel)]="options.sidebartype"
                  value="mini-sidebar"
                  id="minisidebar"
                  (change)="options.sidebartype = 'mini-sidebar'"
                />
                <label class="form-check-label" for="minisidebar"
                  >Mini Sidebar</label
                >
              </div>
              <div class="form-check mt-2">
                <input
                  type="radio"
                  class="form-check-input"
                  name="sidebar"
                  [(ngModel)]="options.sidebartype"
                  value="iconbar"
                  id="iconsidebar"
                  (change)="options.sidebartype = 'iconbar'"
                />
                <label class="form-check-label" for="iconsidebar"
                  >Icon Sidebar</label
                >
              </div>
              <div class="form-check mt-2">
                <input
                  type="radio"
                  class="form-check-input"
                  name="sidebar"
                  [(ngModel)]="options.sidebartype"
                  value="overlay"
                  id="overlaysidebar"
                  (change)="options.sidebartype = 'overlay'"
                />
                <label class="form-check-label" for="overlaysidebar"
                  >Overlay Sidebar</label
                >
              </div>
              <div class="form-check mt-2">
                <input
                  type="radio"
                  class="form-check-input"
                  name="sidebar"
                  [(ngModel)]="options.sidebartype"
                  value="full"
                  id="fullsidebar"
                  (change)="options.sidebartype = 'full'"
                />
                <label class="form-check-label" for="fullsidebar"
                  >Full Sidebar</label
                >
              </div>
            </div>
            <div
              class="p-3 border-bottom"
              *ngIf="options.layout === 'vertical'"
            >
              <h5 class="font-medium mb-4 mt-2">Logo Backgrounds</h5>
              <ul class="theme-color">
                <li class="theme-item">
                  <a
                    href="javascript:void(0)"
                    class="theme-link"
                    data-logobg="skin1"
                    (click)="options.logobg = 'skin1'"
                  ></a>
                </li>
                <li class="theme-item">
                  <a
                    href="javascript:void(0)"
                    class="theme-link"
                    data-logobg="skin2"
                    (click)="options.logobg = 'skin2'"
                  ></a>
                </li>
                <li class="theme-item">
                  <a
                    href="javascript:void(0)"
                    class="theme-link"
                    data-logobg="skin3"
                    (click)="options.logobg = 'skin3'"
                  ></a>
                </li>
                <li class="theme-item">
                  <a
                    href="javascript:void(0)"
                    class="theme-link"
                    data-logobg="skin4"
                    (click)="options.logobg = 'skin4'"
                  ></a>
                </li>
                <li class="theme-item">
                  <a
                    href="javascript:void(0)"
                    class="theme-link"
                    data-logobg="skin5"
                    (click)="options.logobg = 'skin5'"
                  ></a>
                </li>
                <li class="theme-item">
                  <a
                    href="javascript:void(0)"
                    class="theme-link"
                    data-logobg="skin6"
                    (click)="options.logobg = 'skin6'"
                  ></a>
                </li>
              </ul>
            </div>
            <div class="p-3 border-bottom">
              <h5 class="font-medium mb-4 mt-2">Navbar Backgrounds</h5>
              <ul class="theme-color">
                <li class="theme-item">
                  <a
                    href="javascript:void(0)"
                    class="theme-link"
                    data-navbarbg="skin1"
                    (click)="options.navbarbg = 'skin1'"
                  ></a>
                </li>
                <li class="theme-item">
                  <a
                    href="javascript:void(0)"
                    class="theme-link"
                    data-navbarbg="skin2"
                    (click)="options.navbarbg = 'skin2'"
                  ></a>
                </li>
                <li class="theme-item">
                  <a
                    href="javascript:void(0)"
                    class="theme-link"
                    data-navbarbg="skin3"
                    (click)="options.navbarbg = 'skin3'"
                  ></a>
                </li>
                <li class="theme-item">
                  <a
                    href="javascript:void(0)"
                    class="theme-link"
                    data-navbarbg="skin4"
                    (click)="options.navbarbg = 'skin4'"
                  ></a>
                </li>
                <li class="theme-item">
                  <a
                    href="javascript:void(0)"
                    class="theme-link"
                    data-navbarbg="skin5"
                    (click)="options.navbarbg = 'skin5'"
                  ></a>
                </li>
                <li class="theme-item">
                  <a
                    href="javascript:void(0)"
                    class="theme-link"
                    data-navbarbg="skin6"
                    (click)="options.navbarbg = 'skin6'"
                  ></a>
                </li>
              </ul>
            </div>
            <div class="p-3 border-bottom">
              <h5 class="font-medium mb-4 mt-2">Sidebar Backgrounds</h5>
              <ul class="theme-color">
                <li class="theme-item">
                  <a
                    href="javascript:void(0)"
                    class="theme-link"
                    data-sidebarbg="skin1"
                    (click)="options.sidebarbg = 'skin1'"
                  ></a>
                </li>
                <li class="theme-item">
                  <a
                    href="javascript:void(0)"
                    class="theme-link"
                    data-sidebarbg="skin2"
                    (click)="options.sidebarbg = 'skin2'"
                  ></a>
                </li>
                <li class="theme-item">
                  <a
                    href="javascript:void(0)"
                    class="theme-link"
                    data-sidebarbg="skin3"
                    (click)="options.sidebarbg = 'skin3'"
                  ></a>
                </li>
                <li class="theme-item">
                  <a
                    href="javascript:void(0)"
                    class="theme-link"
                    data-sidebarbg="skin4"
                    (click)="options.sidebarbg = 'skin4'"
                  ></a>
                </li>
                <li class="theme-item">
                  <a
                    href="javascript:void(0)"
                    class="theme-link"
                    data-sidebarbg="skin5"
                    (click)="options.sidebarbg = 'skin5'"
                  ></a>
                </li>
                <li class="theme-item">
                  <a
                    href="javascript:void(0)"
                    class="theme-link"
                    data-sidebarbg="skin6"
                    (click)="options.sidebarbg = 'skin6'"
                  ></a>
                </li>
              </ul>
            </div>
          </ng-template>
        </li>

        <li [ngbNavItem]="2">
          <a ngbNavLink><i class="mdi mdi-message-reply fs-6"></i></a>
          <ng-template ngbNavContent>
            <ul class="mailbox list-style-none mt-3">
              <li>
                <div
                  class="message-center chat-scroll"
                  [perfectScrollbar]="config"
                >
                  <a
                    href="#"
                    class="message-item border-bottom d-block text-decoration-none"
                    id="chat_user_1"
                    data-user-id="1"
                  >
                    <span class="user-img position-relative d-inline-block">
                      <img
                        src="assets/images/users/user1.jpg"
                        alt="user"
                        class="rounded-circle"
                      />
                      <span
                        class="profile-status rounded-circle d-inline-block online float-end"
                        data-status="online"
                      ></span>
                    </span>
                    <span class="mail-contnet d-inline-block w-75 ps-3 align-middle">
                      <h5 class="mb-0">Chris Evans</h5>
                      <span class="mail-desc fs-2 d-block text-muted text-truncate">Just see the my admin!</span>
                      <span class="time fs-2 d-block text-muted text-truncate">9:30 AM</span>
                    </span>
                  </a>

                  <a
                    href="#"
                    class="message-item border-bottom d-block text-decoration-none"
                    id="chat_user_2"
                    data-user-id="2"
                  >
                    <span class="user-img position-relative d-inline-block">
                      <img
                        src="assets/images/users/user2.jpg"
                        alt="user"
                        class="rounded-circle"
                      />
                      <span
                        class="profile-status rounded-circle d-inline-block busy float-end"
                        data-status="busy"
                      ></span>
                    </span>
                    <span class="mail-contnet d-inline-block w-75 ps-3 align-middle">
                      <h5 class="mb-0">Ray Hudson</h5>
                      <span class="mail-desc fs-2 d-block text-muted text-truncate"
                        >I've sung a song! See you at</span
                      >
                      <span class="time fs-2 d-block text-muted text-truncate">9:10 AM</span>
                    </span>
                  </a>

                  <a
                    href="#"
                    class="message-item border-bottom d-block text-decoration-none"
                    id="chat_user_3"
                    data-user-id="3"
                  >
                    <span class="user-img position-relative d-inline-block">
                      <img
                        src="assets/images/users/user3.jpg"
                        alt="user"
                        class="rounded-circle"
                      />
                      <span
                        class="profile-status rounded-circle d-inline-block away float-end"
                        data-status="away"
                      ></span>
                    </span>
                    <span class="mail-contnet d-inline-block w-75 ps-3 align-middle">
                      <h5 class="mb-0">Lb James</h5>
                      <span class="mail-desc fs-2 d-block text-muted text-truncate">I am a singer!</span>
                      <span class="time fs-2 d-block text-muted text-truncate">9:08 AM</span>
                    </span>
                  </a>

                  <a
                    href="#"
                    class="message-item border-bottom d-block text-decoration-none"
                    id="chat_user_4"
                    data-user-id="4"
                  >
                    <span class="user-img position-relative d-inline-block">
                      <img
                        src="assets/images/users/user4.jpg"
                        alt="user"
                        class="rounded-circle"
                      />
                      <span
                        class="profile-status rounded-circle d-inline-block offline float-end"
                        data-status="offline"
                      ></span>
                    </span>
                    <span class="mail-contnet d-inline-block w-75 ps-3 align-middle">
                      <h5 class="mb-0">Don Andres</h5>
                      <span class="mail-desc fs-2 d-block text-muted text-truncate">Just see the my admin!</span>
                      <span class="time fs-2 d-block text-muted text-truncate">9:02 AM</span>
                    </span>
                  </a>

                  <a
                    href="#"
                    class="message-item border-bottom d-block text-decoration-none"
                    id="chat_user_5"
                    data-user-id="5"
                  >
                    <span class="user-img position-relative d-inline-block">
                      <img
                        src="assets/images/users/user1.jpg"
                        alt="user"
                        class="rounded-circle"
                      />
                      <span
                        class="profile-status rounded-circle d-inline-block online float-end"
                        data-status="online"
                      ></span>
                    </span>
                    <span class="mail-contnet d-inline-block w-75 ps-3 align-middle">
                      <h5 class="mb-0">Chris Evans</h5>
                      <span class="mail-desc fs-2 d-block text-muted text-truncate">Just see the my admin!</span>
                      <span class="time fs-2 d-block text-muted text-truncate">9:30 AM</span>
                    </span>
                  </a>

                  <a
                    href="#"
                    class="message-item border-bottom d-block text-decoration-none"
                    id="chat_user_6"
                    data-user-id="6"
                  >
                    <span class="user-img position-relative d-inline-block">
                      <img
                        src="assets/images/users/user2.jpg"
                        alt="user"
                        class="rounded-circle"
                      />
                      <span
                        class="profile-status rounded-circle d-inline-block busy float-end"
                        data-status="busy"
                      ></span>
                    </span>
                    <span class="mail-contnet d-inline-block w-75 ps-3 align-middle">
                      <h5 class="mb-0">Ray Hudson</h5>
                      <span class="mail-desc fs-2 d-block text-muted text-truncate"
                        >I've sung a song! See you at</span
                      >
                      <span class="time fs-2 d-block text-muted text-truncate">9:10 AM</span>
                    </span>
                  </a>

                  <a
                    href="#"
                    class="message-item border-bottom d-block text-decoration-none"
                    id="chat_user_7"
                    data-user-id="7"
                  >
                    <span class="user-img position-relative d-inline-block">
                      <img
                        src="assets/images/users/user3.jpg"
                        alt="user"
                        class="rounded-circle"
                      />
                      <span
                        class="profile-status rounded-circle d-inline-block away float-end"
                        data-status="away"
                      ></span>
                    </span>
                    <span class="mail-contnet d-inline-block w-75 ps-3 align-middle">
                      <h5 class="mb-0">Lb James</h5>
                      <span class="mail-desc fs-2 d-block text-muted text-truncate">I am a singer!</span>
                      <span class="time fs-2 d-block text-muted text-truncate">9:08 AM</span>
                    </span>
                  </a>

                  <a
                    href="#"
                    class="message-item border-bottom d-block text-decoration-none"
                    id="chat_user_8"
                    data-user-id="8"
                  >
                    <span class="user-img position-relative d-inline-block">
                      <img
                        src="assets/images/users/user4.jpg"
                        alt="user"
                        class="rounded-circle"
                      />
                      <span
                        class="profile-status rounded-circle d-inline-block offline float-end"
                        data-status="offline"
                      ></span>
                    </span>
                    <span class="mail-contnet d-inline-block w-75 ps-3 align-middle">
                      <h5 class="mb-0">Don Andres</h5>
                      <span class="mail-desc fs-2 d-block text-muted text-truncate">Just see the my admin!</span>
                      <span class="time fs-2 d-block text-muted text-truncate">9:02 AM</span>
                    </span>
                  </a>

                  <a
                    href="#"
                    class="message-item border-bottom d-block text-decoration-none"
                    id="chat_user_9"
                    data-user-id="9"
                  >
                    <span class="user-img position-relative d-inline-block">
                      <img
                        src="assets/images/users/user1.jpg"
                        alt="user"
                        class="rounded-circle"
                      />
                      <span
                        class="profile-status rounded-circle d-inline-block online float-end"
                        data-status="online"
                      ></span>
                    </span>
                    <span class="mail-contnet d-inline-block w-75 ps-3 align-middle">
                      <h5 class="mb-0">Chris Evans</h5>
                      <span class="mail-desc fs-2 d-block text-muted text-truncate">Just see the my admin!</span>
                      <span class="time fs-2 d-block text-muted text-truncate">9:30 AM</span>
                    </span>
                  </a>

                  <a
                    href="#"
                    class="message-item border-bottom d-block text-decoration-none"
                    id="chat_user_10"
                    data-user-id="10"
                  >
                    <span class="user-img position-relative d-inline-block">
                      <img
                        src="assets/images/users/user2.jpg"
                        alt="user"
                        class="rounded-circle"
                      />
                      <span
                        class="profile-status rounded-circle d-inline-block busy float-end"
                        data-status="busy"
                      ></span>
                    </span>
                    <span class="mail-contnet d-inline-block w-75 ps-3 align-middle">
                      <h5 class="mb-0">Ray Hudson</h5>
                      <span class="mail-desc fs-2 d-block text-muted text-truncate"
                        >I've sung a song! See you at</span
                      >
                      <span class="time fs-2 d-block text-muted text-truncate">9:10 AM</span>
                    </span>
                  </a>

                  <a
                    href="#"
                    class="message-item border-bottom d-block text-decoration-none"
                    id="chat_user_11"
                    data-user-id="11"
                  >
                    <span class="user-img position-relative d-inline-block">
                      <img
                        src="assets/images/users/user3.jpg"
                        alt="user"
                        class="rounded-circle"
                      />
                      <span
                        class="profile-status rounded-circle d-inline-block away float-end"
                        data-status="away"
                      ></span>
                    </span>
                    <span class="mail-contnet d-inline-block w-75 ps-3 align-middle">
                      <h5 class="mb-0">Lb James</h5>
                      <span class="mail-desc fs-2 d-block text-muted text-truncate">I am a singer!</span>
                      <span class="time fs-2 d-block text-muted text-truncate">9:08 AM</span>
                    </span>
                  </a>

                  <a
                    href="#"
                    class="message-item border-bottom d-block text-decoration-none"
                    id="chat_user_12"
                    data-user-id="12"
                  >
                    <span class="user-img position-relative d-inline-block">
                      <img
                        src="assets/images/users/user4.jpg"
                        alt="user"
                        class="rounded-circle"
                      />
                      <span
                        class="profile-status rounded-circle d-inline-block offline float-end"
                        data-status="offline"
                      ></span>
                    </span>
                    <span class="mail-contnet d-inline-block w-75 ps-3 align-middle">
                      <h5 class="mb-0">Don Andres</h5>
                      <span class="mail-desc fs-2 d-block text-muted text-truncate">Just see the my admin!</span>
                      <span class="time fs-2 d-block text-muted text-truncate">9:02 AM</span>
                    </span>
                  </a>
                </div>
              </li>
            </ul>
          </ng-template>
        </li>
        <li [ngbNavItem]="3">
          <a ngbNavLink><i class="mdi mdi-star-circle fs-6"></i></a>
          <ng-template ngbNavContent>
            <div class="p-3">
              <h6 class="mt-3 mb-4">Activity Timeline</h6>
              <div class="steamline position-relative border-start ms-4">
                <div class="sl-item my-3 py-1 border-bottom">
                  <div
                    class="
                      sl-left
                      float-start
                      text-center
                      rounded-circle
                      text-white
                      me-3
                      bg-success
                    "
                  >
                    <i class="ti-user"></i>
                  </div>
                  <div class="sl-right ps-4">
                    <div class="font-medium">
                      Meeting today
                      <span class="sl-date"> 5pm</span>
                    </div>
                    <div class="desc">you can write anything</div>
                  </div>
                </div>
                <div class="sl-item my-3 py-1 border-bottom">
                  <div
                    class="
                      sl-left
                      float-start
                      text-center
                      rounded-circle
                      text-white
                      me-3
                      bg-info
                    "
                  >
                    <i class="fas fa-image"></i>
                  </div>
                  <div class="sl-right ps-4">
                    <div class="font-medium">Send documents to Clark</div>
                    <div class="desc">Lorem Ipsum is simply</div>
                  </div>
                </div>
                <div class="sl-item my-3 py-1 border-bottom">
                  <div
                    class="
                      sl-left
                      float-start
                      text-center
                      rounded-circle
                      text-white
                      me-3
                    "
                  >
                    <img
                      class="rounded-circle"
                      alt="user"
                      src="assets/images/users/user2.jpg"
                    />
                  </div>
                  <div class="sl-right ps-4">
                    <div class="font-medium">
                      Go to the Doctor
                      <span class="sl-date">5 minutes ago</span>
                    </div>
                    <div class="desc">Contrary to popular belief</div>
                  </div>
                </div>
                <div class="sl-item my-3 py-1 border-bottom">
                  <div
                    class="
                      sl-left
                      float-start
                      text-center
                      rounded-circle
                      text-white
                      me-3
                    "
                  >
                    <img
                      class="rounded-circle"
                      alt="user"
                      src="assets/images/users/user1.jpg"
                    />
                  </div>
                  <div class="sl-right ps-4">
                    <div>
                      <a href="javascript:void(0)">Stephen</a>
                      <span class="sl-date">5 minutes ago</span>
                    </div>
                    <div class="desc">Approve meeting with tiger</div>
                  </div>
                </div>
                <div class="sl-item my-3 py-1 border-bottom">
                  <div
                    class="
                      sl-left
                      float-start
                      text-center
                      rounded-circle
                      text-white
                      me-3
                      bg-primary
                    "
                  >
                    <i class="ti-user"></i>
                  </div>
                  <div class="sl-right ps-4">
                    <div class="font-medium">
                      Meeting today
                      <span class="sl-date"> 5pm</span>
                    </div>
                    <div class="desc">you can write anything</div>
                  </div>
                </div>
                <div class="sl-item my-3 py-1 border-bottom">
                  <div
                    class="
                      sl-left
                      float-start
                      text-center
                      rounded-circle
                      text-white
                      me-3
                      bg-info
                    "
                  >
                    <i class="fas fa-image"></i>
                  </div>
                  <div class="sl-right ps-4">
                    <div class="font-medium">Send documents to Clark</div>
                    <div class="desc">Lorem Ipsum is simply</div>
                  </div>
                </div>
                <div class="sl-item my-3 py-1 border-bottom">
                  <div
                    class="
                      sl-left
                      float-start
                      text-center
                      rounded-circle
                      text-white
                      me-3
                    "
                  >
                    <img
                      class="rounded-circle"
                      alt="user"
                      src="assets/images/users/user4.jpg"
                    />
                  </div>
                  <div class="sl-right ps-4">
                    <div class="font-medium">
                      Go to the Doctor
                      <span class="sl-date">5 minutes ago</span>
                    </div>
                    <div class="desc">Contrary to popular belief</div>
                  </div>
                </div>
                <div class="sl-item my-3 py-1">
                  <div
                    class="
                      sl-left
                      float-start
                      text-center
                      rounded-circle
                      text-white
                      me-3
                    "
                  >
                    <img
                      class="rounded-circle"
                      alt="user"
                      src="assets/images/users/user1.jpg"
                    />
                  </div>
                  <div class="sl-right ps-4">
                    <div>
                      <a href="javascript:void(0)">Stephen</a>
                      <span class="sl-date">5 minutes ago</span>
                    </div>
                    <div class="desc">Approve meeting with tiger</div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </li>
      </ul>

      <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
  </aside>
  <div class="chat-windows"></div>
</div>
