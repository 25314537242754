import { UserLogin } from './../models/UserLogin';
import { catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Subject, throwError, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { AccountInfo } from '@azure/msal-common';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private user = new BehaviorSubject<any>(null);
  public loggedInUser = this.user.asObservable();
  public tokenExpirationTimer: any;

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  public registerUser(userRegistration: any) {
    return this.http.post(`${environment.ridely_api}authentication`, userRegistration);
  }

  public requestPasswordReset(email: string) {
    return this.http.get(`${environment.ridely_api}authentication/password/reset/${email}`);
  }

  public resetPassword(resetForm: any) {
    debugger;
    return this.http.post(`${environment.ridely_api}authentication/password/reset`, resetForm);
  }
  
  /**
   * 
   * @param loginData login data object with username and password
   * @returns 
   */
  public login(loginData: any) {
    return this.http
    .post(`${environment.ridely_api}authentication/login`, { username: loginData.username, password: loginData.password })
    .pipe(catchError(this.handleError), tap((res: any) => {
      this.handleAuthentication(res.username, res.token, res.email, res.expires);
    }));
  }

  public loginViaOTP(idno: string) {
    return this.http.get(`${environment.ridely_api}authentication/login?idno=${idno}`);
  }

  validateUser(activeAccount: AccountInfo) {
    return this.http.post(`${environment.ridely_api}authentication/validate/login`, activeAccount)
    .pipe(catchError(this.handleError), tap((res: any) => {
      this.handleAuthentication(res.username, res.token, res.email, res.expires);
    }));;
  }

  public logout() {
    this.user.next(null);
    localStorage.removeItem('user');
    this.router.navigate(['/home/index']);

    if (this.tokenExpirationTimer) {
      clearTimeout(this.tokenExpirationTimer);
    }

    this.tokenExpirationTimer = null;
  }

  /**
   * @method autoLogin automatically logs the user in when the application starts
   */
  public autoLogin() {
    const userData = JSON.parse(localStorage.getItem('user') || '{}');

    if (!userData || userData == '') {
      this.router.navigate(['/authentication/login']);
      return;
    }

    const loadedUser = new UserLogin(userData._userName, userData._token, userData._email, userData._tokenExpirationDate);

    this.user.next(loadedUser);
  }

  /**
   * @method autoLogout logout the user after the token expiration date.
   * @param expirationDuration token expiration duration in miliseconds.
   */
  public autoLogOut(expirationDuration: number) {
    setTimeout(() => {
      this.logout();
    } , expirationDuration);
  }

  public setUser(user: any) {
    this.user.next(user);
  }

  /**
   * handles the authentication logic releated to the authentication of the user.
   * @param username 
   * @param token 
   * @param tokenExpirationDate 
   */
  private handleAuthentication(username: string, token: string, email: string, tokenExpirationDate: number) {
    
    const userLogin = new UserLogin(username, token, email, tokenExpirationDate);
    localStorage.setItem('user', JSON.stringify(userLogin));
    this.user.next(userLogin);

    const tokenDate = new Date(tokenExpirationDate * 1000); // convert to data object from unix time
    const expiresIn = tokenDate.getTime() - new Date().getTime(); // get the time difference between the token expiration date and the current date.
    this.autoLogOut(expiresIn); // auto logout the user after the token expiration date.
  }

  /**
   * Handle Http operation that failed.
   */
  private handleError(errorRes: HttpErrorResponse) {
    let errorMessage = 'An unknown error occurred!';

      // errors that are returned from the server are in the format of {message: 'error message'}. Therefore, we can check for the message key to get the error message.
      if (!errorRes.error && !errorRes.message) {
        return throwError(errorMessage);
      }

      switch (errorRes.statusText) {
        case 'Unauthorized':
          errorMessage = 'Invalid username or password!';
          break;

        case 'Forbidden':
          errorMessage = 'You are not authorized to access this resource!';
          break;

        case 'Not Found':
          errorMessage = 'The requested resource was not found!';
      }
      return throwError(errorMessage);
  }
}
