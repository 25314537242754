import { Injectable } from '@angular/core';
import {HttpTransportType, HubConnection, HubConnectionBuilder, RetryContext} from "@microsoft/signalr";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class GeolocationHubService {
  public geoLocationConnection: HubConnection;
  constructor() {
    this.geoLocationConnection = new HubConnectionBuilder()
      .withUrl(`${environment.RYDLY_HUB}geolocationhub`, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      }).withAutomaticReconnect({
        nextRetryDelayInMilliseconds(retryContext: RetryContext): number | null {
          if (retryContext.elapsedMilliseconds < 150000) {
            return 0;
          } else {
            // If the elapsed time exceeds 60 seconds, stop reconnecting.
            return null;
          }
        }
      }).build();

    this.onConnected();
  }

  onConnected() {
    this.geoLocationConnection.on("SuccessfulConnection", (data) => {
      sessionStorage.setItem("socketId", data);
    });
  }

  closed() {
    this.geoLocationConnection.onclose(async () => {
      setTimeout(() => {
        this.startConnection();
      }, 3000);
    });
  }

  startConnection() {
    this.geoLocationConnection
      .start()
      .then(() => {
        console.info("Geo location connection started");

        this.reconnecting();
        this.reconnected();
        this.closed();
      })
  }

  reconnecting() {
    this.geoLocationConnection.onreconnecting(async () => {
    });
  }

  reconnected() {
    this.geoLocationConnection.onreconnected(async (data: any) => {
      this.retrieveUserData(data);
    });
  }

  retrieveUserData(data: any) {
    console.log(data);
  }
}
